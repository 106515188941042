import React, { useEffect, useState } from "react";
import { apiUrl } from "../../api";
import { DeleteFilled, EditFilled, SearchOutlined } from "@ant-design/icons";
import showToast from "../../util";
import {
  Button,
  Card,
  Cascader,
  CascaderProps,
  Input,
  Divider,
  Modal,
  Table,
} from "antd";
import { DefaultOptionType } from "antd/es/select";
interface Provider {
  id: string | null;
  name: string;
  nameAr: string;
  packageNames: string[];
  terminalModels: TerminalBrandModel[];
  terminalModelsDto: (string | number)[];
  prefixes: string[];
  status: string;
}

interface Option {
  value: string | number;
  label: string;
  children?: Option[];
  disableCheckbox?: boolean;
}

interface TerminalModel {
  id: string;
  name: string;
}

interface TerminalBrandModel {
  brandId: string;
  brandName: string;
  modelName: string;
  modelId: string;
}

interface Brand {
  id: string;
  name: string;
  terminalModels: TerminalModel[];
}

const MSP: React.FC = () => {
  const [providers, setProviders] = useState<Provider[]>([]);
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState<any[] | null>(null);
  const [showResults, setShowResults] = useState(false);
  const [provider, setProvider] = useState<Provider>({
    id: null,
    name: "",
    nameAr: "",
    terminalModels: [],
    terminalModelsDto: [],
    packageNames: [] as string[],
    prefixes: [] as string[],
    status: "ACTIVATED",
  });
  const [tableData, setTableData] = useState<any[]>([]);
  const [terminalsBrands, setTerminalsBrands] = useState<Brand[]>([]);
  const [options, setOptions] = useState<Option[]>([]);
  const [modalStatus, setModalStatus] = useState("hide");
  const [packageEditingMode, setPackageEditingMode] = useState<string | null>(
    null
  );
  const [currenPackage, setCurrenPackage] = useState<string>("");

  const [prefixEditingMode, setprefixEditingMode] = useState<string | null>(
    null
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentPrefix, setCurrenPrefix] = useState<string>("");
  const [errors, setErrors] = useState<{
    [key: string]: string | string[] | string[][];
  }>({});

  const addPackage = (packageName: string) => {
    if (!packageName || provider?.packageNames.includes(packageName)) return;

    setProvider((prev) => ({
      ...prev!,
      packageNames: packageEditingMode
        ? prev!.packageNames.map((p) =>
            p === packageEditingMode ? packageName : p
          )
        : [...prev!.packageNames, packageName],
    }));
    setPackageEditingMode(null);
    setCurrenPackage("");
  };

  useEffect(() => {
    setOptions(
      terminalsBrands.map((brand) => ({
        label: brand.name,
        value: brand.id,
        children: brand.terminalModels.map((model) => ({
          label: model.name,
          value: `${brand.id}-${model.id}`,
        })),
      }))
    );
  }, [terminalsBrands]);

  const onChange: CascaderProps<Option, "value", true>["onChange"] = (
    value
  ) => {
    const updated = value
      .flatMap((v) => (Array.isArray(v) ? v[1].toString().split("-")[1] : ""))
      .filter(Boolean);
    setProvider((prev) => ({
      ...prev!,
      terminalModelsDto: updated,
    }));
  };
  const handleSearch = async (value: string) => {
    if (!value.trim()) {
      setShowResults(false);
      return;
    }

    setLoading(true);
    try {
      const response = await fetch(`${apiUrl}/providers/p/search?q=${value}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      const data = await response.json();
      setResults(data || []);
      setShowResults(true);
    } catch (error) {
      console.error("Error fetching search results:", error);
    } finally {
      setLoading(false);
    }
  };
  const handleClear = () => {
    setResults(null);
    setShowResults(false); 
  };

  // setTableData
  useEffect(() => {
    setTableData(
      providers.map((provider) => ({
        key: provider.id,
        name: provider.name,
        nameAr: provider.nameAr,
        terminals: provider.terminalModels.map((model, index) => {
          if (index === provider.terminalModels.length - 1) {
            return `${model?.brandName} - ${model?.modelName}`;
          }
          return `${model?.brandName} - ${model?.modelName} / `;
        }),
        packages: provider.packageNames.join(" / "),
        prefixes: provider.prefixes.join(" / "),
        status: provider.status,
      }))
    );
  }, [providers]);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Name (Arabic)",
      dataIndex: "nameAr",
      key: "nameAr",
    },
    {
      title: "Terminals",
      dataIndex: "terminals",
      key: "terminals",
    },
    {
      title: "Packages",
      dataIndex: "packages",
      key: "packages",
    },
    {
      title: "Prefixes",
      dataIndex: "prefixes",
      key: "prefixes",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status: string) =>
        status === "ACTIVATED" ? (
          <span className="text-green-500 bg-green-100 rounded-full px-4 py-1">
            Active
          </span>
        ) : (
          <span className="text-red-500 bg-red-100 rounded-full px-4 py-1">
            Inactive
          </span>
        ),
    },
  ];

  const addPrefix = (prefix: string) => {
    if (!prefix || provider.prefixes.includes(prefix)) return;

    setProvider((prev) => ({
      ...prev!,
      prefixes: prefixEditingMode
        ? prev!.prefixes.map((p) => (p === prefixEditingMode ? prefix : p))
        : [...prev!.prefixes, prefix],
    }));

    setprefixEditingMode(null);
    setCurrenPrefix("");
  };

  const saveProvider = async (provider: Provider) => {
    setIsLoading(true);
    if (modalStatus === "edit") {
      const response = await fetch(`${apiUrl}/providers/${provider.id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        body: JSON.stringify(provider),
      });
      if (!response.ok) {
        showToast(await response.text(), "warning");
      } else {
        showToast("Provider updated successfully", "success");

        fetchProviders();
        resetModal();
      }
    } else {
      const response = await fetch(`${apiUrl}/providers`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        body: JSON.stringify(provider),
      });
      if (!response.ok) {
        showToast(await response.text(), "warning");
      } else {
        showToast("Provider added successfully", "success");
        fetchProviders();
        resetModal();
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchProviders();
    fetchBrands();
  }, []);

  const fetchProviders = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${apiUrl}/providers`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setProviders(data);
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchBrands = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${apiUrl}/brands`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setTerminalsBrands(data);
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const displayRender: CascaderProps<Option>["displayRender"] = (
    labels,
    selectedOptions = []
  ) =>
    labels.map((label, i) => {
      const option = selectedOptions[i];
      if (i === labels.length - 1) {
        return <span key={option.value}>{label}</span>;
      }
      return <span key={option.value}>{label} - </span>;
    });

  const filter = (inputValue: string, path: DefaultOptionType[]) =>
    path.some(
      (option) =>
        (option.label as string)
          .toLowerCase()
          .indexOf(inputValue.toLowerCase()) > -1
    );
  const validateForm = () => {
    const errors: { [key: string]: string | string[] | string[][] } = {};
    if (!provider.name) {
      errors.name = "Name is required";
    }
    if (!provider.nameAr) {
      errors.nameAr = "Name (Arabic) is required";
    }
    if (
      (provider.terminalModelsDto && provider.terminalModelsDto.length === 0) ||
      provider.terminalModelsDto == null
    ) {
      errors.terminalModels = "At least one terminal model is required";
    }
    if (provider.packageNames.length === 0) {
      errors.packages = "At least one package is required";
    }
    if (provider.prefixes.length === 0) {
      errors.prefixes = "At least one prefix is required";
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  function showDetails(id: any) {
    const provider = providers.find((p) => p.id === id);
    if (provider) {
      provider.terminalModelsDto = provider.terminalModels.map(
        (model) => `${model.modelId}`
      );
      setProvider(provider);
      setModalStatus("edit");
    }
  }
  useEffect(() => {
  },[provider]);
  function resetModal(): void {
    setProvider({
      id: null,
      name: "",
      nameAr: "",
      terminalModels: [],
      terminalModelsDto: [],
      packageNames: [] as string[],
      prefixes: [] as string[],
      status: "ACTIVATED",
    });
    setModalStatus("hide");
    setErrors({});
  }

  return (
    <div className="p-4">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold mb-4">Vendor Management</h1>
        <Button
          type="primary"
          onClick={() => {
            setModalStatus("add");
          }}
          style={{ marginBottom: 16 }}
        >
          Add Provider
        </Button>
      </div>

      {/* search input */}
      <div className="relative p-4 max-w-xl">
        <Input
        prefix={<SearchOutlined className="mr-4"/>}
          placeholder="Search for MSP..."
          className="w-full p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          onChange={(e) => {
            const value = e.target.value;
            if (value.trim()) {
              handleSearch(value);
            } else {
              handleClear();
            }
          }}
          onFocus={() => setShowResults(true)}
          onBlur={() => setTimeout(() => setShowResults(false), 100)}
        />

        {/* Overlay for Search Results */}
        {showResults && (
          <div className="absolute  left-0 right-0 mx-4 bg-white  shadow-lg z-10 max-h-80 overflow-y-auto">
            {loading ? (
              <div className="text-center py-4">
                <div className="loader">Loading...</div>
              </div>
            ) : results && results.length > 0 ? (
              <ul>
                {results.map((item, index) => (
                  <li
                    key={index}
                    className="p-4 hover:bg-gray-100 border-b last:border-b-0"
                    onMouseDown={(e) => e.preventDefault()}
                    onClick={() => {
                      showDetails(item.id);
                    }}
                  >
                    <strong>{item.name}</strong> - {item.nameAr}
                  </li>
                ))}
              </ul>
            ) : results && results.length == 0 ? (
              <div className="text-center py-4 text-gray-500">
                No results found.
              </div>
            ): null}
          </div>
        )}
      </div>

      {/* table */}
      <div className="space-y-4">
        <Table
          dataSource={tableData}
          columns={columns}
          pagination={false}
          onRow={(record) => {
            return {
              onClick: () => {
                showDetails(record.key);
              }, // click row
            };
          }}
        />
      </div>

      {/* Provider Modal */}
      {modalStatus !== "hide" && (
        <Modal
          title={
            modalStatus === "edit" ? (
              <h1 className="text-2xl">Edit Provider</h1>
            ) : (
              <h1 className="text-2xl">MSP on-boarding</h1>
            )
          }
          open={modalStatus !== "hide"}
          onCancel={() => resetModal()}
          footer={null}
          className="top-1"
          styles={{
            content: { boxShadow: "none", padding: ".5rem" },
            header: { margin: "0", paddingLeft: "1rem" },
          }}
        >
          {/* MSP Name Card*/}
          <Card bordered={true} className="mb-2">
            {/* card header */}
            <h1 className="text-lg font-bold">MSP Name</h1>
            <p className="text-gray-500 mb-2">
              provide the name of the Merchant Service Provider. this name will
              be displayed to the merchants in the system.
            </p>

            {/* name input */}
            <div className="mb-6">
              <label className="font-bold text-base ">Name</label>
              <Input
                className="p-2 mt-1"
                placeholder="Enter the MSP's name"
                value={provider.name}
                onChange={(e) =>
                  setProvider({ ...provider, name: e.target.value })
                }
              />
              {errors.name && <p className="text-red-600">{errors.name}</p>}
            </div>

            {/* nameAr input */}
            <div className="mb-6">
              <label className="font-bold text-base ">Name (Arabic)</label>
              <Input
                className="p-2 mt-1"
                placeholder="Enter the MSP's name in Arabic"
                value={provider.nameAr}
                onChange={(e) =>
                  setProvider({ ...provider, nameAr: e.target.value })
                }
              />
              {errors.nameAr && <p className="text-red-600">{errors.nameAr}</p>}
            </div>
          </Card>

          {/* Terminals Brands & Models */}
          <Card bordered={true} className="mb-2">
            <div>
              <h1 className="text-lg font-bold">Terminals Brands & Models</h1>
              <p className="text-gray-500 mb-2">
                Add terminals brands & models associated with this MSP.
              </p>
            </div>
            <label className="font-bold text-base">Terminals</label>
            <Cascader
              style={{ width: "100%" }}
              className="mt-1 "
              defaultValue={provider.terminalModels.map((model) => {
                return [model.brandId, `${model.brandId}-${model.modelId}`];
              })}
              options={options}
              onChange={onChange}
              showSearch={{ filter }}
              displayRender={displayRender}
              multiple
              maxTagCount={10}
              placeholder="Select terminals brands & models"
              showCheckedStrategy="SHOW_CHILD"
            />
            {errors.terminalModels && (
              <p className="text-red-600">{errors.terminalModels}</p>
            )}
          </Card>

          {/* packages names & prefixes */}
          <Card className="mb-2">
            {/*card header*/}
            <div>
              <h1 className="text-lg font-bold">Packages Names & Prefixes</h1>
              <p className="text-gray-500 mb-2">
                Add packages names & prefixes.
              </p>
            </div>

            {/* package name input */}
            <div>
              <label className="font-bold text-base ">Package Name</label>
              <div className="flex justify-between mt-1 items-center gap-1">
                <Input
                  className="p-2"
                  placeholder="Enter the mada package name"
                  onChange={(e) => setCurrenPackage(e.target.value)}
                  value={currenPackage}
                />
                <Button
                  type="default"
                  className="h-10"
                  onClick={() => addPackage(currenPackage)}
                >
                  {packageEditingMode ? "Save" : "Add"}
                </Button>
              </div>
            </div>
            {errors.packages && (
              <p className="text-red-600">{errors.packages}</p>
            )}

            {/* list of packages names */}
            <div className="flex flex-col mt-6 bg-gray-100 rounded-lg">
              {provider.packageNames.map((packageName, index) => (
                <div key={packageName}>
                  <div
                    className="flex justify-between items-center gap-1
                     mx-2 my-2"
                  >
                    <p>{packageName}</p>
                    <div className="flex gap-1">
                      <Button
                        type="default"
                        shape="circle"
                        icon={<EditFilled />}
                        onClick={() => {
                          setCurrenPackage(packageName);
                          setPackageEditingMode(packageName);
                        }}
                      />
                      <Button
                        type="default"
                        shape="circle"
                        icon={<DeleteFilled />}
                        onClick={() => {
                          setProvider((prev) => ({
                            ...prev,
                            packageNames: prev.packageNames.filter(
                              (p) => p !== packageName
                            ),
                          }));
                        }}
                      />
                    </div>
                  </div>
                  {provider.packageNames.length - 1 > index && (
                    <Divider className="my-0" />
                  )}
                </div>
              ))}
            </div>

            <Divider />

            {/* prefixes name input */}
            <div>
              <label className="font-bold text-base ">2-Digits prefixes</label>
              <div className="flex justify-between mt-1 items-center gap-1">
                <Input
                  className="p-2"
                  placeholder="Enter the provider prefix"
                  onChange={(e) => setCurrenPrefix(e.target.value)}
                  value={currentPrefix}
                />
                <Button
                  type="default"
                  className="h-10"
                  onClick={() => addPrefix(currentPrefix)}
                >
                  {prefixEditingMode ? "Save" : "Add"}
                </Button>
              </div>
              {errors.prefixes && (
                <p className="text-red-600">{errors.prefixes}</p>
              )}
            </div>

            {/* list of prefixes */}
            <div className="flex flex-col mt-6 bg-gray-100 rounded-lg">
              {provider.prefixes.map((digit, index) => (
                <div key={digit}>
                  <div
                    className="flex justify-between items-center gap-1
                     mx-2 my-2"
                  >
                    <p>{digit}</p>
                    <div className="flex gap-1">
                      <Button
                        type="default"
                        shape="circle"
                        icon={<EditFilled />}
                        onClick={() => {
                          setCurrenPrefix(digit);
                          setprefixEditingMode(digit);
                        }}
                      />
                      <Button
                        type="default"
                        shape="circle"
                        icon={<DeleteFilled />}
                        onClick={() => {
                          setProvider((prev) => ({
                            ...prev,
                            prefixes: prev.prefixes.filter((p) => p !== digit),
                          }));
                        }}
                      />
                    </div>
                  </div>
                  {provider.prefixes.length - 1 > index && (
                    <Divider className="my-0" />
                  )}
                </div>
              ))}
            </div>
          </Card>

          <Card className="mb-2">
            <div>
              <h1 className="text-lg font-bold">Status</h1>
              <p className="text-gray-500 mb-2">Set the status of the MSP.</p>
            </div>
            <div className="flex gap-4 items-center">
              <label className="font-bold text-base">Status</label>
              <select
                className="p-2"
                value={provider.status}
                onChange={(e) =>
                  setProvider({ ...provider, status: e.target.value })
                }
              >
                <option value="ACTIVATED">ACTIVE</option>
                <option value="DEACTIVATED">INACTIVE</option>
              </select>
            </div>
          </Card>

          {/* buttons */}
          <div className="flex justify-end gap-2">
            <Button
              disabled={isLoading}
              type="default"
              onClick={() => {
                resetModal();
              }}
            >
              Cancel
            </Button>

            <Button
              loading={isLoading}
              type="primary"
              onClick={() => {
                if (validateForm()) {
                  saveProvider(provider);
                }
              }}
            >
              {modalStatus === "edit" ? "Update" : "Save"}
            </Button>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default MSP;
