import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { AuthProvider } from "./AuthContext";
import Login from "./pages/Login";
import Layout from "./pages/Layout";
import {Layout as AdminLayout}from "./pages/admin/layout";
import Cashier from "./pages/Cashier";
import ProtectedRoute from "./components/ProtectedRoute";
import Transactions from "./pages/Transactions";
import Terminals from "./pages/Terminals";
import { WebSocketProvider } from "./WebSocketContext";
import { ToastContainer } from "react-toastify";
import MerchantOnboarding from "./pages/MerchantOnboarding";
import Dashboard from "./pages/admin/dashboard";
import Home from "./pages/admin/Home";
import MSP from "./pages/admin/msp";
import Brands from "./pages/admin/Brands";

const App = () => {
  return (
    <AuthProvider>
      <WebSocketProvider>
        <Router>
          <Routes>
            {/* <Route path="/cuppertino/remalcomplex/login" element={isAutghenticated ? <Navigate to="/" /> : <Login />} /> */}
            <Route path="/cuppertino/remalcomplex/login" element={<Login />} />

            <Route
              path="/home"
              Component={() => {
                window.location.href = "/home.html";
                return null;
              }}
            />

            <Route
              path="/cuppertino/remalcomplex/new"
              element={<MerchantOnboarding />}
            />

            <Route
              path="/"
              element={
                <ProtectedRoute
                  element={
                    <Layout>
                      <Cashier />
                    </Layout>
                  }
                />
              }
            />
            <Route
              path="/terminals"
              element={
                <ProtectedRoute
                  element={
                    <Layout>
                      <Terminals />
                    </Layout>
                  }
                />
              }
            />
            <Route
              path="/transactions"
              element={
                <ProtectedRoute
                  element={
                    <Layout>
                      <Transactions />
                    </Layout>
                  }
                />
              }
            />
            <Route
              path="/neopass-admin2"
              element={
                <ProtectedRoute
                  element={
                    <Layout>
                      <Transactions />
                    </Layout>
                  }
                />
              }
            />

            <Route path="neopass-admin" element={<Dashboard />}>
              <Route
                index
                element={
                  <AdminLayout>
                    <Home />
                  </AdminLayout>
                }
              />
              <Route
                path="msps"
                element={
                  <AdminLayout>
                    <MSP />
                  </AdminLayout>
                }
              />
               <Route
                path="brands"
                element={
                  <AdminLayout>
                    <Brands />
                  </AdminLayout>
                }
              />
            </Route>
            
          </Routes>
        </Router>
      </WebSocketProvider>
      <ToastContainer />
    </AuthProvider>
  );
};

export default App;
