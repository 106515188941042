import React, { useState, useEffect } from "react";
import { apiUrl } from "../api";
import { useNavigate } from "react-router-dom";

interface Cashier {
  name: string;
  cashierUsername: string;
  cashierPassword: string;
  mspId: string;
  linkedTerminalModelId: string;
  linkedTerminalId: string;
}

interface Branch {
  name: string;
  address: string;
  cashiers: Cashier[];
}

interface TerminalModel {
  id: string;
  brandName: string;
  modelName: string;
}

interface Msp {
  id: string;
  name: string;
  terminalsModels: TerminalModel[];
}

interface Bank {
  id: string;
  name: string;
}

const MerchantOnboarding: React.FC = () => {
  const [step, setStep] = useState(1);
  const navigate = useNavigate();
  const [personalInfo, setPersonalInfo] = useState({
    username: "",
    password: "",
    confirmPassword: "",
  });
  const [businessInfo, setBusinessInfo] = useState({
    businessName: "",
    cr: "",
    email: "",
    mobileNumber: "",
  });
  const [banks, setBanks] = useState<Bank[]>([]);
  const [selectedBankId, setSelectedBankId] = useState<string | null>(null);
  const [msps, setMsps] = useState<Msp[]>([]);
  const [branches, setBranches] = useState<Branch[]>([]);
  const [availableTerminalsModels, setAvailableTerminalsModels] = useState<{
    [key: string]: TerminalModel[];
  }>({});
  const [errors, setErrors] = useState<{
    [key: string]: string | string[] | string[][];
  }>({});

  useEffect(() => {
    const fetchBanks = async () => {
      const response = await fetch(`${apiUrl}/banks`);
      const data = await response.json();
      setBanks(data);
    };
    const fetchMsps = async () => {
      const response = await fetch(`${apiUrl}/providers`);
      const data = await response.json();
      setMsps(data);
    };
    fetchBanks();
    fetchMsps();
  }, []);

  const fetchTerminalsModels = async (
    mspId: string,
    branchIndex: number,
    cashierIndex: number
  ) => {
    const msp = msps.find((m) => m.id == mspId);
    if (msp) {
      setAvailableTerminalsModels((prev) => ({
        ...prev,
        [`${branchIndex}-${cashierIndex}`]: msp.terminalsModels,
      }));
    }
  };

  const validateStep = () => {
    const newErrors: { [key: string]: string | string[] | string[][] } = {};
    if (step === 1) {
      if (!personalInfo.username) newErrors.username = "Username is required";
      if (!personalInfo.password) newErrors.password = "Password is required";
      if (personalInfo.password !== personalInfo.confirmPassword)
        newErrors.confirmPassword = "Passwords do not match";
    } else if (step === 2) {
      if (!businessInfo.businessName)
        newErrors.businessName = "Business name is required";
      if (!businessInfo.cr) newErrors.cr = "CR is required";
      if (!businessInfo.email) newErrors.email = "Email is required";
      if (!businessInfo.mobileNumber)
        newErrors.mobileNumber = "Mobile number is required";
      if (!selectedBankId) newErrors.bank = "Bank selection is required";
    } else if (step === 3) {
      const branchNameErrors: string[] = [];
      const branchAddressErrors: string[] = [];
      const branchCashierErrors: string[] = [];
      const branchCashierNameErrors: string[][] = [];
      const branchCashierMspErrors: string[][] = [];
      const branchCashierTerminalErrors: string[][] = [];
      const branchCashierUsernameErrors: string[][] = [];
      const branchCashierPasswordErrors: string[][] = [];
      if (branches.length === 0) {
        newErrors.branch = "add at least one branch";
      }
      branches.forEach((branch, index) => {
        if (!branch.name) {
          branchNameErrors[index] = "Branch name is required";
          newErrors.branchName = branchNameErrors;
        }
        if (!branch.address) {
          branchAddressErrors[index] = "Branch address is required";
          newErrors.branchAddress = branchAddressErrors;
        }

        if (branch.cashiers.length === 0) {
          branchCashierErrors[index] = "add at least one cashier";
          newErrors.branchCashier = branchCashierErrors;
        }
        branchCashierNameErrors[index] = [];
        branchCashierMspErrors[index] = [];
        branchCashierTerminalErrors[index] = [];
        branchCashierUsernameErrors[index] = [];
        branchCashierPasswordErrors[index] = [];
        branch.cashiers.forEach((cashier, index2) => {
          if (!cashier.name) {
            branchCashierNameErrors[index][index2] = "Cashier name is required";
            newErrors.branchCashierName = branchCashierNameErrors;
          }
          if (!cashier.cashierUsername) {
            branchCashierUsernameErrors[index][index2] =
              "Cashier username is required";
            newErrors.branchCashierUsername = branchCashierUsernameErrors;
          }
          if (!cashier.cashierPassword) {
            branchCashierPasswordErrors[index][index2] =
              "Cashier password is required";
            newErrors.branchCashierPassword = branchCashierPasswordErrors;
          }
          if (!cashier.mspId) {
            branchCashierMspErrors[index][index2] = "MSP is required";
            newErrors.branchCashierMsp = branchCashierMspErrors;
          }
          if (!cashier.linkedTerminalModelId) {
            branchCashierTerminalErrors[index][index2] =
              "Terminal Model is required";
            newErrors.branchCashierTerminalModel = branchCashierTerminalErrors;
          }
        });
      });
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const nextStep = () => {
    if (validateStep()) setStep(step + 1);
  };

  const prevStep = () => setStep(step - 1);

  const addBranch = () => {
    setBranches([...branches, { name: "", address: "", cashiers: [] }]);
  };

  const removeBranch = (index: number) => {
    const updatedBranches = branches.filter((_, i) => i !== index);
    setBranches(updatedBranches);
  };

  const updateBranch = (index: number, key: string, value: string) => {
    const updatedBranches = [...branches];
    updatedBranches[index] = { ...updatedBranches[index], [key]: value };
    setBranches(updatedBranches);
  };

  const addCashier = (branchIndex: number) => {
    const updatedBranches = [...branches];
    updatedBranches[branchIndex].cashiers.push({
      name: "",
      mspId: "",
      linkedTerminalId: "",
      linkedTerminalModelId: "",
      cashierUsername:"",
      cashierPassword:""
    });
    setBranches(updatedBranches);
  };

  const removeCashier = (branchIndex: number, cashierIndex: number) => {
    const updatedBranches = [...branches];
    updatedBranches[branchIndex].cashiers = updatedBranches[
      branchIndex
    ].cashiers.filter((_, i) => i !== cashierIndex);
    setBranches(updatedBranches);
  };

  const updateCashier = (
    branchIndex: number,
    cashierIndex: number,
    key: string,
    value: string
  ) => {
    const updatedBranches = [...branches];
    updatedBranches[branchIndex].cashiers[cashierIndex] = {
      ...updatedBranches[branchIndex].cashiers[cashierIndex],
      [key]: value,
    };
    setBranches(updatedBranches);

    if (key === "mspId") {
      fetchTerminalsModels(value, branchIndex, cashierIndex);
    }
  };

  const handleSubmit = async () => {
    if (validateStep()) {
      const payload = {
        personalInfo,
        businessInfo,
        selectedBankId,
        branches,
      };

      const response = await fetch(`${apiUrl}/merchants/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        alert("Onboarding successful!");
        navigate("/cuppertino/remalcomplex/login");
      } else {
        alert("Error during onboarding.");
      }
    }
  };

  return (
    <div className="max-w-3xl mx-auto p-8 bg-white shadow-md rounded-lg mt-8">
      <h2 className="text-2xl font-semibold mb-6">Merchant Onboarding</h2>

      {step === 1 && (
        <div className="form-step">
          <h3 className="text-xl font-semibold mb-4">
            Step 1: Personal Information
          </h3>

          <div className="mb-4">
            <label className="block mb-1 font-semibold">Username</label>
            <input
              type="text"
              className="w-full p-2 border rounded"
              value={personalInfo.username}
              onChange={(e) =>
                setPersonalInfo({ ...personalInfo, username: e.target.value })
              }
            />
            {errors.username && (
              <p className="text-red-600">{errors.username}</p>
            )}
          </div>

          <div className="mb-4">
            <label className="block mb-1 font-semibold">Password</label>
            <input
              type="password"
              className="w-full p-2 border rounded"
              value={personalInfo.password}
              onChange={(e) =>
                setPersonalInfo({ ...personalInfo, password: e.target.value })
              }
            />
            {errors.password && (
              <p className="text-red-600">{errors.password}</p>
            )}
          </div>

          <div className="mb-4">
            <label className="block mb-1 font-semibold">Confirm Password</label>
            <input
              type="password"
              className="w-full p-2 border rounded"
              value={personalInfo.confirmPassword}
              onChange={(e) =>
                setPersonalInfo({
                  ...personalInfo,
                  confirmPassword: e.target.value,
                })
              }
            />
            {errors.confirmPassword && (
              <p className="text-red-600">{errors.confirmPassword}</p>
            )}
          </div>

          <button
            className="px-4 py-2 bg-blue-600 text-white rounded"
            onClick={nextStep}
          >
            Next
          </button>
        </div>
      )}

      {step === 2 && (
        <div className="form-step">
          <h3 className="text-xl font-semibold mb-4">
            Step 2: Business Information
          </h3>

          <div className="mb-4">
            <label className="block mb-1 font-semibold">Business Name</label>
            <input
              type="text"
              className="w-full p-2 border rounded"
              value={businessInfo.businessName}
              onChange={(e) =>
                setBusinessInfo({
                  ...businessInfo,
                  businessName: e.target.value,
                })
              }
            />
            {errors.businessName && (
              <p className="text-red-600">{errors.businessName}</p>
            )}
          </div>

          <div className="mb-4">
            <label className="block mb-1 font-semibold">CR</label>
            <input
              type="text"
              className="w-full p-2 border rounded"
              value={businessInfo.cr}
              onChange={(e) =>
                setBusinessInfo({ ...businessInfo, cr: e.target.value })
              }
            />
            {errors.cr && <p className="text-red-600">{errors.cr}</p>}
          </div>

          <div className="mb-4">
            <label className="block mb-1 font-semibold">Email</label>
            <input
              type="email"
              className="w-full p-2 border rounded"
              value={businessInfo.email}
              onChange={(e) =>
                setBusinessInfo({ ...businessInfo, email: e.target.value })
              }
            />
            {errors.email && <p className="text-red-600">{errors.email}</p>}
          </div>

          <div className="mb-4">
            <label className="block mb-1 font-semibold">Mobile Number</label>
            <input
              type="text"
              className="w-full p-2 border rounded"
              value={businessInfo.mobileNumber}
              onChange={(e) =>
                setBusinessInfo({
                  ...businessInfo,
                  mobileNumber: e.target.value,
                })
              }
            />
            {errors.mobileNumber && (
              <p className="text-red-600">{errors.mobileNumber}</p>
            )}
          </div>

          <div className="mb-4">
            <label className="block mb-1 font-semibold">Bank</label>
            <select
              className="w-full p-2 border rounded"
              value={selectedBankId || ""}
              onChange={(e) => setSelectedBankId(e.target.value)}
            >
              <option value="">Select Bank</option>
              {banks.map((bank) => (
                <option key={bank.id} value={bank.id}>
                  {bank.name}
                </option>
              ))}
            </select>
            {errors.bank && <p className="text-red-600">{errors.bank}</p>}
          </div>

          <button
            className="px-4 py-2 bg-gray-600 text-white rounded mr-2"
            onClick={prevStep}
          >
            Back
          </button>
          <button
            className="px-4 py-2 bg-blue-600 text-white rounded"
            onClick={nextStep}
          >
            Next
          </button>
        </div>
      )}

      {step === 3 && (
        <div className="form-step">
          <h3 className="text-xl font-semibold mb-4">
            Step 3: Branches, MSPs, and Cashiers
          </h3>
          <ul>
            {branches.map((branch, branchIndex) => (
              <div key={branchIndex} className="border p-4 mb-4">
                <h4 className="text-lg font-semibold mb-2">
                  Branch {branchIndex + 1}
                </h4>

                <div className="mb-2">
                  <label className="block mb-1 font-semibold">
                    Branch Name
                  </label>
                  <input
                    type="text"
                    className="w-full p-2 border rounded"
                    value={branch.name}
                    onChange={(e) =>
                      updateBranch(branchIndex, "name", e.target.value)
                    }
                  />
                  {errors.branchName && errors.branchName[branchIndex] && (
                    <p className="text-red-600">
                      {errors.branchName[branchIndex]}
                    </p>
                  )}
                </div>

                <div className="mb-2">
                  <label className="block mb-1 font-semibold">
                    Branch Address
                  </label>
                  <input
                    type="text"
                    className="w-full p-2 border rounded"
                    value={branch.address}
                    onChange={(e) =>
                      updateBranch(branchIndex, "address", e.target.value)
                    }
                  />
                  {errors.branchName && errors.branchAddress[branchIndex] && (
                    <p className="text-red-600">
                      {errors.branchAddress[branchIndex]}
                    </p>
                  )}
                </div>

                <h4 className="text-lg font-semibold mb-2">Cashiers</h4>

                {branch.cashiers.map((cashier, cashierIndex) => (
                  <div key={cashierIndex} className="mb-2 ml-4 border-b p-4">
                    <label className="block mb-1 font-semibold">
                      Name
                    </label>
                    <input
                      type="text"
                      className="w-full p-2 border rounded"
                      value={cashier.name}
                      onChange={(e) =>
                        updateCashier(
                          branchIndex,
                          cashierIndex,
                          "name",
                          e.target.value
                        )
                      }
                    />
                    {errors.branchCashierName &&
                      errors.branchCashierName[branchIndex][cashierIndex] && (
                        <p className="text-red-600">
                          {errors.branchCashierName[branchIndex][cashierIndex]}
                        </p>
                      )}

                    <label className="block mb-1 font-semibold">
                      Cashier Username
                    </label>
                    <input
                      type="text"
                      className="w-full p-2 border rounded"
                      value={cashier.cashierUsername}
                      onChange={(e) =>
                        updateCashier(
                          branchIndex,
                          cashierIndex,
                          "cashierUsername",
                          e.target.value
                        )
                      }
                    />
                    {errors.branchCashierUsername &&
                      errors.branchCashierUsername[branchIndex][
                        cashierIndex
                      ] && (
                        <p className="text-red-600">
                          {
                            errors.branchCashierUsername[branchIndex][
                              cashierIndex
                            ]
                          }
                        </p>
                      )}

                    <label className="block mb-1 font-semibold">
                      Cashier Password (must be changed on first login)
                    </label>
                    <input
                       type="password"
                      className="w-full p-2 border rounded"
                      value={cashier.cashierPassword}
                      onChange={(e) =>
                        updateCashier(
                          branchIndex,
                          cashierIndex,
                          "cashierPassword",
                          e.target.value
                        )
                      }
                    />
                    {errors.branchCashierPassword &&
                      errors.branchCashierPassword[branchIndex][
                        cashierIndex
                      ] && (
                        <p className="text-red-600">
                          {
                            errors.branchCashierPassword[branchIndex][
                              cashierIndex
                            ]
                          }
                        </p>
                      )}

                    <label className="block mb-1 font-semibold mt-2">MSP</label>
                    <select
                      className="w-full p-2 border rounded"
                      value={cashier.mspId}
                      onChange={(e) =>
                        updateCashier(
                          branchIndex,
                          cashierIndex,
                          "mspId",
                          e.target.value
                        )
                      }
                    >
                      <option value="">Select MSP</option>
                      {msps.map((msp) => (
                        <option key={msp.id} value={msp.id}>
                          {msp.name}
                        </option>
                      ))}
                    </select>
                    {errors.branchCashierMsp &&
                      errors.branchCashierMsp[branchIndex][cashierIndex] && (
                        <p className="text-red-600">
                          {errors.branchCashierMsp[branchIndex][cashierIndex]}
                        </p>
                      )}

                    <label className="block mb-1 font-semibold mt-2">
                      Terminal Model
                    </label>
                    <select
                      className="w-full p-2 border rounded"
                      value={cashier.linkedTerminalModelId}
                      onChange={(e) =>
                        updateCashier(
                          branchIndex,
                          cashierIndex,
                          "linkedTerminalModelId",
                          e.target.value
                        )
                      }
                    >
                      <option value="">Select Terminal Model</option>
                      {(
                        availableTerminalsModels[
                          `${branchIndex}-${cashierIndex}`
                        ] || []
                      ).map((terminalModel) => (
                        <option key={terminalModel.id} value={terminalModel.id}>
                         {terminalModel.brandName} - {terminalModel.modelName}
                        </option>
                      ))}
                    </select>
                    {errors.branchCashierTerminalModel &&
                      errors.branchCashierTerminalModel[branchIndex][
                        cashierIndex
                      ] && (
                        <p className="text-red-600">
                          {
                            errors.branchCashierTerminalModel[branchIndex][
                              cashierIndex
                            ]
                          }
                        </p>
                      )}

                    <label className="block mb-1 font-semibold">
                      Terminal ID
                    </label>
                    <input
                      type="text"
                      className="w-full p-2 border rounded"
                      value={cashier.linkedTerminalId}
                      onChange={(e) =>
                        updateCashier(
                          branchIndex,
                          cashierIndex,
                          "linkedTerminalId",
                          e.target.value
                        )
                      }
                    />

                    <button
                      className="px-4 py-2 bg-red-600 text-white rounded mt-2"
                      onClick={() => removeCashier(branchIndex, cashierIndex)}
                    >
                      Remove Cashier
                    </button>
                  </div>
                ))}

                <button
                  className="px-4 py-2 bg-green-600 text-white rounded mb-4 mr-2"
                  onClick={() => addCashier(branchIndex)}
                >
                  Add Cashier
                </button>

                <button
                  className="px-4 py-2 bg-red-600 text-white rounded"
                  onClick={() => removeBranch(branchIndex)}
                >
                  Remove Branch
                </button>
                {errors.branchCashier && errors.branchCashier[branchIndex] && (
                  <p className="text-red-600">
                    {errors.branchCashier[branchIndex]}
                  </p>
                )}
              </div>
            ))}
          </ul>
          <button
            className="px-4 py-2 bg-green-600 text-white rounded mb-4 block"
            onClick={addBranch}
          >
            Add Branch
          </button>
          {errors.branch && <p className="text-red-600">{errors.branch}</p>}
          <button
            className="px-4 py-2 bg-gray-600 text-white rounded mr-2"
            onClick={prevStep}
          >
            Back
          </button>
          <button
            className="px-4 py-2 bg-blue-600 text-white rounded"
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
      )}
    </div>
  );
};

export default MerchantOnboarding;
