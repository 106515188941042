import React, { useEffect, useState } from "react";
import { Button, Modal, Input, Collapse, Divider } from "antd";
import axios from "axios";
import { apiUrl } from "../../api";
import { DeleteFilled, EditFilled } from "@ant-design/icons";
import showToast from "../../util";
interface TerminalModel {
  id: number;
  name: string;
}
interface Brand {
  id: number;
  name: string;
  terminalModels: TerminalModel[];
}

const Brands: React.FC = () => {
  const [brands, setBrands] = useState<Brand[]>([]);
  const [brand, setBrand] = useState<Brand>({
    id: 0,
    name: "",
    terminalModels: [],
  });
  const [isEditingTerminalModel, setIsEditingTerminalModel] = useState(false);
  const [terminalModel, setTerminalModel] = useState<TerminalModel>({
    id: 0,
    name: "",
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [modalStatus, setModalStatus] = useState("hide");
  const [errors, setErrors] = useState<{
    [key: string]: string | string[] | string[][];
  }>({});

  useEffect(() => {
    fetchData();
  }, []);
  const saveTerminalModel = (model: TerminalModel) => {
    if (!model || !model.name) return;
    // check if name already exist in terminalModels
    if (brand.terminalModels.some((p) => p.name === model.name)) {
      return;
    }

    setBrand((prev) => ({
      ...prev,
      terminalModels: isEditingTerminalModel
        ? prev.terminalModels.map((p) =>
            p.id === terminalModel.id ? model : p
          )
        : [...prev.terminalModels, model],
    }));
    setIsEditingTerminalModel(false);
    setTerminalModel({ id: 0, name: "" });
  };

  const fetchData = async () => {
    setIsLoading(true);
    console.log("fetching data", isLoading);
    try {
      const token = localStorage.getItem("accessToken");
      const response = await axios.get(`${apiUrl}/brands`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setBrands(response.data);
    } catch (error) {
      console.error("Failed to fetch data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAdd = () => {
    setModalStatus("add");
  };

  const handleEdit = (brand: Brand) => {
    setBrand(brand);
    setModalStatus("edit");
  };

  // const handleDelete = async (id: number) => {
  //   try {
  //     const token = localStorage.getItem("accessToken");
  //     await axios.delete(`${apiUrl}/terminal-models/${id}`, {
  //       headers: { Authorization: `Bearer ${token}` },
  //     });
  //     fetchData();
  //   } catch (error) {
  //     console.error("Failed to delete record:", error);
  //   }
  // };

  const handleSave = async (provider: Brand) => {
    setIsLoading(true);
    if (modalStatus === "edit") {
      try {
        const response = await fetch(`${apiUrl}/brands/${provider.id}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
          body: JSON.stringify(provider),
        });
        if (!response.ok) {
          showToast(await response.text(), "warning");
        } else {
          showToast("Trminal brand updated successfully", "success");

          fetchData();
          resetForm();
        }
      } catch {
        console.log("error");
      } finally {
        setIsLoading(false);
      }
    } else {
      try {
        const response = await fetch(`${apiUrl}/brands`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
          body: JSON.stringify(provider),
        });
        if (!response.ok) {
          showToast(await response.text(), "warning");
        } else {
          showToast("Terminal brand added successfully", "success");
          fetchData();
          resetForm();
        }
      } catch {
        console.log("error");
      } finally {
        setIsLoading(false);
      }
    }
  };

  const resetForm = () => {
    setBrand({ id: 0, name: "", terminalModels: [] });
    setErrors({});
    setModalStatus("hide");
    setTerminalModel({ id: 0, name: "" });
  };

  const onchange = (value: string) => {
    setTerminalModel((prev) => ({ ...prev!, name: value }));
  };

  return (
    <div>
      <h1 className="text-2xl font-bold mb-4">Terminals Models</h1>
      <Button type="primary" onClick={handleAdd} style={{ marginBottom: 16 }}>
        Add Brand
      </Button>

      <div className="m-4">
        <Collapse accordion>
          {brands.map((brand) => (
            <Collapse.Panel header={brand.name} key={brand.id}>
              <div className="flex flex-row gap-3">
                {brand.terminalModels.map((model) => (
                  <p key={model.name} className="px-3 py-1 border rounded-full">
                    {model.name}
                  </p>
                ))}
              </div>
              <Button
                type="default"
                onClick={() => handleEdit(brand)}
                className="mt-2"
              >
                Edit
              </Button>
            </Collapse.Panel>
          ))}
        </Collapse>
      </div>

      <Modal
        title={modalStatus === "edit" ? "Edit Brand" : "Add Brand"}
        open={modalStatus !== "hide"}
        onCancel={() => resetForm()}
        footer={null}
      >
        <div>
          {/* name input */}
          <div className="mb-6">
            <label className="font-bold text-base ">Name</label>
            <Input
              className="p-2 mt-1"
              placeholder="Enter the brand name"
              value={brand.name}
              onChange={(e) => setBrand({ ...brand, name: e.target.value })}
            />
            {errors.name && <p className="text-red-600">{errors.name}</p>}
          </div>

          {/* list of models  */}
          <h4 className="font-bold text-base ">Terminal Models List</h4>
          <div className="flex flex-col mt-2 bg-gray-100 rounded-lg">
            {brand.terminalModels.map((terminalModel, index) => (
              <div key={terminalModel.name}>
                <div
                  className="flex justify-between items-center gap-1
                     mx-2 my-2"
                >
                  <p>{terminalModel.name}</p>
                  <div className="flex gap-1">
                    <Button
                      type="default"
                      shape="circle"
                      icon={<EditFilled />}
                      onClick={() => {
                        setTerminalModel(terminalModel);
                        setIsEditingTerminalModel(true);
                      }}
                    />
                    <Button
                      type="default"
                      shape="circle"
                      icon={<DeleteFilled />}
                      onClick={() => {
                        setBrand((prev) => ({
                          ...prev,
                          terminalModels: prev.terminalModels.filter(
                            (p) => p !== terminalModel
                          ),
                        }));
                      }}
                    />
                  </div>
                </div>
                {brand.terminalModels.length - 1 > index && (
                  <Divider className="my-0" />
                )}
              </div>
            ))}
          </div>
          <div className="mb-6">
            {errors.terminalModels && (
              <p className="text-red-600">{errors.terminalModels}</p>
            )}
          </div>

          {/* models input */}
          <div>
            <label className="font-bold text-base ">
              {isEditingTerminalModel ? "Edit" : "Add"} Terminal Model
            </label>
            <div className="flex justify-between mt-1 items-center gap-1">
              <Input
                className="p-2"
                placeholder="Enter the terminal model name"
                onChange={(e) => onchange(e.target.value)}
                value={terminalModel.name}
              />
              <Button
                type="default"
                className="h-10"
                onClick={() =>
                  terminalModel && saveTerminalModel(terminalModel)
                }
              >
                {isEditingTerminalModel ? "Save" : "Add"}
              </Button>
            </div>
          </div>
          <Button
            type="primary"
            loading={isLoading}
            onClick={() => handleSave(brand)}
            className="w-full mt-4"
          >
            {modalStatus === "edit" ? "Update" : "Add"}
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default Brands;
