import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import { useAuth } from "../../useAuth";

const Layout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const { logout,role } = useAuth();

  const toggleSidebar = () => {
    setIsSidebarOpen((prevState) => !prevState);
  };
if (role == "admin")
    return (
    <div className="flex min-h-screen">
      {/* Sidebar */}
      <div
        className={`fixed inset-y-0 left-0 w-64  p-6 transform transition-transform duration-300 z-10
          ${isSidebarOpen ? "translate-x-0" : "-translate-x-full"} `}
      >
        <h2 className="text-xl font-semibold mb-6">NeoPass Admin</h2>
        <nav>
          <ul>
            <li className="mb-4">
              <Link to="/neopass-admin">Dashboard</Link>
            </li>
            <li className="mb-4">
              <Link to="/neopass-admin/msps">Vendor Management</Link>
            </li>
            <li className="mb-4">
              <Link to="/neopass-admin/brands">Brands & Models</Link>
            </li>
            
          </ul>
        </nav>
      </div>

      <div
        className={`flex-1 bg-gray-50 transition-all duration-300
          ${isSidebarOpen ? "ml-64" : "ml-2"}`}
      >
        {/* top bar */}
        <div className={`flex  flex-grow gap-3 justify-end p-2 bg-gray-200 `}>
          <button
            className="hover:text-blue-500 hover:border-b border-blue-500"
            onClick={() => {
              logout();
            }}
          >
            logout
          </button>
        </div>
        <div className="pl-10 pt-2">
          {/* Toggle Button */}
          <button
            onClick={toggleSidebar}
            className={`fixed top-12 z-2 bg-white text-primary p-1 rounded-full transition-transform duration-300 
          ${isSidebarOpen ? "left-[260px]" : "left-4"} `}
          >
            {isSidebarOpen ? (
              <ChevronLeftIcon className="w-6 h-6" /> // Left arrow when sidebar is open
            ) : (
              <ChevronRightIcon className="w-6 h-6" /> // Right arrow when sidebar is hidden
            )}
          </button>
          <div>
            {/* Main Content */}
            <div>{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
  else return <div>no content</div>;
};

export { Layout };
