import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import TransactionDialog from "../components/TransactionDialog";
import { useWebSocket } from "../useWebsocket";
import { useAuth } from "../useAuth";
import { Tooltip as ReactTooltip } from "react-tooltip";

const Layout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [status, setStatus] = useState<"approved" | "declined" | "canceled">(
    "approved"
  );
  const [transactionId, setTransactionId] = useState<string>("");
  const { connect, message, isConnected, disconnect } = useWebSocket();
  const { logout, role } = useAuth();
  const navigate = useNavigate();

  const toggleSidebar = () => {
    setIsSidebarOpen((prevState) => !prevState);
  };

  const handleCloseDialog = () => {
    setShowDialog(false);
  };

  const handleShowDialog = (status: "approved" | "declined" | "canceled") => {
    setStatus(status);
    setShowDialog(true);
  };

  useEffect(() => {
    if (message.substring(0, 3) === "PAY") {
      const st = message.substring(3, 4);
      setTransactionId(message.substring(4, message.length));
      if (st === "0") handleShowDialog("declined");
      else if (st === "1") handleShowDialog("approved");
    }
  }, [message]);

  useEffect(() => {
    if (role === "cashier") connect();
  }, [connect, role]);

  useEffect(() => {
    if (role === "admin") {
      navigate("/neopass-admin");
    }
  }, [role, navigate]);

  return (
    <div className="flex min-h-screen">
      {/* Sidebar */}
      <div
        className={`fixed inset-y-0 left-0 w-64 bg-gray-800 text-white p-6 transform transition-transform duration-300 z-10
          ${isSidebarOpen ? "translate-x-0" : "-translate-x-full"} `}
      >
        <h2 className="text-xl font-semibold mb-6">Admin Panel</h2>
        <nav>
          <ul>
            <li className="mb-4">
              <Link to="/" className="hover:text-gray-300">Cashier</Link>
            </li>
            {role !== "cashier" && (
              <li className="mb-4">
                <Link to="/terminals" className="hover:text-gray-300">Terminals</Link>
              </li>
            )}
            <li className="mb-4">
              <Link to="/transactions" className="hover:text-gray-300">Transactions</Link>
            </li>
          </ul>
        </nav>
      </div>

      <div
        className={`flex-1 bg-gray-50 transition-all duration-300
          ${isSidebarOpen ? "ml-64" : "ml-2"}`}
      >
        {/* Top bar */}
        <div className="flex justify-end p-2 bg-gray-200">
          {!isConnected && role === "cashier" && (
            <button
              className="hover:text-blue-500 hover:border-b border-blue-500"
              onClick={connect}
            >
              Connect
            </button>
          )}
          <button
            className="hover:text-blue-500 hover:border-b border-blue-500 ml-4"
            onClick={() => {
              disconnect();
              logout();
            }}
          >
            Logout
          </button>

          <div
            data-tooltip-id="my-tooltip"
            data-tooltip-content="Platform connection status"
            className="ml-4"
          >
            {isConnected && role === "cashier" && (
              <svg viewBox="0 0 24 24" className="w-6 h-6">
                <circle cx="12" cy="12" r="10" fill="green" />
              </svg>
            )}
            {!isConnected && role === "cashier" && (
              <svg viewBox="0 0 24 24" className="w-6 h-6">
                <circle cx="12" cy="12" r="10" fill="red" />
              </svg>
            )}
          </div>
          <ReactTooltip id="my-tooltip" />
        </div>

        <div className="pl-10 pt-2">
          {/* Toggle Button */}
          <button
            onClick={toggleSidebar}
            className={`fixed top-12 z-20 bg-white text-primary p-1 rounded-full transition-transform duration-300 
          ${isSidebarOpen ? "left-[260px]" : "left-4"} `}
          >
            {isSidebarOpen ? (
              <ChevronLeftIcon className="w-6 h-6" />
            ) : (
              <ChevronRightIcon className="w-6 h-6" />
            )}
          </button>
          <div>
            {/* Main Content */}
            <div>{children}</div>
          </div>
        </div>
      </div>

      {showDialog && (
        <TransactionDialog
          transactionId={transactionId}
          status={status}
          onClose={handleCloseDialog}
        />
      )}
    </div>
  );
};

export default Layout;
