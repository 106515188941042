import { useState } from "react";
import { products } from "../data/products";
import { Product } from "../types";
import CheckoutDialog from "../components/CheckoutDialog";
import { apiUrl } from "../api";
import water from "../assets/water-4998513_640.png";
import "react-toastify/dist/ReactToastify.css";
import showToast from "../util";

const Cashier = () => {
  const [orderItems, setOrderItems] = useState<Product[]>([]);
  const [isCheckoutOpen, setIsCheckoutOpen] = useState(false);


 const addToOrder = (product: Product) => {
    setOrderItems((prevItems) => [...prevItems, product]);
  };

  const totalSum = orderItems.reduce((sum, product) => sum + product.price, 0);

  const openCheckoutDialog = () => {
    if (orderItems.length > 0) {
      setIsCheckoutOpen(true);
    } else {
      showToast("Add items to the order before checking out.", "warning");
    }
  };

  const closeCheckoutDialog = () => {
    setIsCheckoutOpen(false);
  };

  const handlePayment = async () => {
    const token = localStorage.getItem("accessToken");
    const response = await fetch(`${apiUrl}/transactions/send-purchase`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({amount: totalSum.toString(),ref_number:"090" }),
    });

    if (response.ok) {
      showToast("Payment request sent", "success", 2000);
      setOrderItems([]);
      setIsCheckoutOpen(false);
    } else {
      showToast("Terminal not connected", "warning"); //todo handle other cases
    }
  };

  return (
    <div className="bg-gray-50 min-h-screen">
      <h1 className="text-2xl font-bold mb-6 md:mb-8 text-center md:text-left">
        Merchant Cashier
      </h1>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 md:gap-6">
        {products.map((product) => (
          <div key={product.id} className="bg-white p-4 shadow rounded-lg">
            {/* Responsive Image */}
            <img
              src={water}
              alt={product.name}
              className="w-full h-40 object-contain mb-4 rounded"
            />
            <h2 className="text-lg font-semibold">{product.name}</h2>
            <p className="text-gray-500 mb-4">{product.price.toFixed(2)} SAR</p>
            <button
              onClick={() => addToOrder(product)}
              className="bg-primary text-white px-4 py-2 rounded-md w-full"
            >
              Add to Order
            </button>
          </div>
        ))}
      </div>

      {/* Order Summary */}
      <div className="mt-8">
        <h2 className="text-xl font-bold">Order Summary</h2>
        <ul className="mt-4 space-y-2  w-[40rem]">
          {orderItems.map((item, index) => (
            <li key={index} className="flex justify-between border-b-2">
              <span>{item.name}</span>
              <span >{item.price.toFixed(2)} SAR</span>
            </li>
            
          ))}
        </ul>
        <div className="mt-4">
          <p className="text-lg font-semibold">
            Total: {totalSum.toFixed(2)} SAR
          </p>
          <button
            onClick={openCheckoutDialog}
            className="mt-4 bg-primary text-white px-4 py-2 rounded-md w-full sm:w-auto"
          >
            Checkout
          </button>
        </div>
      </div>

      {/* Checkout Dialog */}
      <CheckoutDialog
        isOpen={isCheckoutOpen}
        totalAmount={totalSum}
        onClose={closeCheckoutDialog}
        onPay={handlePayment}
      />
    
    </div>
  );
};

export default Cashier;
